import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppDocument, Visibility } from '@core/models/document.model';
import { environment } from '@env/environment';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { FileType } from '../enums/file-type';
import { FileDownloadService } from './file/file-download.service';

export type DmsDocumentInput = {
  path?: string;
  kerberosAccountId: string;
  externalId: string;
  parentCustomerId?: string;
  departmentId?: string;
  title: string;
  type: string;
  scope: string;
  originallyCreatedAt: Date;
  expiresAt?: Date;
  visibility: Visibility;
  createdByUsername: string;
  source: string;
};

export type FilterCriteria = {
  'tags[]'?: string | string[];
  'collection[]'?: string | string[];
  'folders[]'?: string | string[];
};

@Injectable({
  providedIn: 'root',
})
export class DocumentsService extends EntityCollectionServiceBase<AppDocument> {
  public constructor(
    private readonly httpClient: HttpClient,
    serviceElementsFactory: EntityCollectionServiceElementsFactory,
    private downloadService: FileDownloadService,
  ) {
    super('Document', serviceElementsFactory);
  }

  /**
   * Retrieves the documents that belong to the customer.
   * The endpoint requires the kerberosAccountId from the Access token.
   *
   * @returns {Observable<void>} An observable that emits void when the request is successful.
   */
  public getCustomerDocuments(): Observable<void> {
    return this.httpClient
      .get(`${environment.config.dms.url}/documents/customer`, {
        params: {
          'tags[]': environment.config.dms.tags,
        },
      })
      .pipe(
        first(),
        map((documents) => {
          this.upsertManyInCache(documents as AppDocument[]);
        }),
      );
  }

  /**
   * Download the document and open it on a new browser tab
   * Works with pdf only.
   */
  public async downloadDocument(item: AppDocument): Promise<void> {
    try {
      const url = `${environment.config.dms.url}/documents/${item.id}/content`;

      const fileName = item.title || item.type; // use 'title', fallback on 'type' if 'title' is not available
      const fileExtension = '.pdf'; // Review: do we use Excel? Since we just share pdf with customer contentType is 'application/pdf'

      this.downloadService.download(url, FileType.Pdf, `${fileName}${fileExtension}`);
    } catch (error: unknown) {
      console.warn(`Failed to download document, due to:`, error);
    }
  }
}
